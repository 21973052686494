<template>
  <div class="vld-parent">
    <loading :active="isLoading"
    :can-cancel="true"
    :loader="bars"
    :opacity='0.5'
    :on-cancel="onCancel"
    :is-full-page="fullPage"><img src="/image/loading.svg"></loading>
  </div>

  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/works.svg" alt="Works"><span class="sub">制作実績</span></h1>
          <!--span class="button-prev" @click="prev">PREV</span-->

          <router-link to="/works" class="button-prev"><span class="-more">LIST</span></router-link>
        </div>
      </div>

      <section class="">
        <div class="container-wrapper container-pad -nopaddingtop">
          <div class="copy-fukidashi -yatta"><p class="comment"><img src="@/assets/image/works/yatta.svg" alt="やったこと"></p></div>

          <div class="grid-block -cols2 -reverse">
            <div>
              <h2 class="heading-copy" v-if="item.data.title">{{item.data.title.rendered}}</h2>
              <div class="meta-data">
                <time v-if="item.data.date">{{ setDate(item.data.date ) }}</time>
                <p class="client" v-if="item.data.acf">{{item.data.acf.client}}</p>
                <router-link :to="{name: 'Service',hash: '#service-menu'}"><ul class="meta-cat"></ul></router-link>
              </div>
              <div class="container-body" v-if="item.data.content" v-html="item.data.content.rendered"></div>
            </div>
            <div class="image-list">
              <template v-if="item.data.acf && item.data.acf.w_main_image.url"><div class="thumb"><img v-bind:src="item.data.acf.w_main_image.url" alt="" class="_radius"></div></template>
              <template v-if="item.data.acf && item.data.acf.w_main_image_01.url"><div class="thumb"><img v-bind:src="item.data.acf.w_main_image_01.url" alt="" class="_radius"></div></template>
              <template v-if="item.data.acf && item.data.acf.w_main_image_02.url"><div class="thumb"><img v-bind:src="item.data.acf.w_main_image_02.url" alt="" class="_radius"></div></template>
              <template v-if="item.data.acf && item.data.acf.w_main_image_03.url"><div class="thumb"><img v-bind:src="item.data.acf.w_main_image_03.url" alt="" class="_radius"></div></template>
              <template v-if="item.data.acf && item.data.acf.w_caption_image"><p class="copy-caption">＊ 写真はイメージです。</p></template>

            </div>
          </div>

        </div>
      </section>
    </div>
  </main>

</template>

<script>
import { onMounted, onUpdated, onUnmounted, reactive, ref } from 'vue'
import { useRoute,useRouter } from 'vue-router'
import axios from "axios";
import moment from 'moment'
import Loading from 'vue-loading-overlay';
require( 'vue-loading-overlay/dist/vue-loading.css')

export default {
  components: {
    Loading
  },
  setup() {
    const API_HOST = process.env.VUE_APP_APIRESTHOST;
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(false);
    const item = reactive({
      data:[],
    })
    const  prev =  () => {
      //router.go(-1)
      window.history.length > 1 ? router.go(-1) : router.push('/')
    }

    const  getItem = async () => {
      isLoading.value = true
      await axios.get('//' + API_HOST + '/c/wp-json/wp/v2/posts/' + route.params.id)
      .then( response => {
        //posts.value = response.data
        item.data = response.data
        let metaCat = document.querySelector('.meta-cat');
        for(let i in item.data.category_detail) {
          let cat = item.data.category_detail[i]
          if( cat.parent ){
            let li = document.createElement('li')
            let span = document.createElement('span')
            span.classList.add('icon', cat.me.category_nicename)
            span.textContent += cat.me.name
            li.appendChild(span)
            metaCat.appendChild(li)
          }
        }
        setTimeout(() => {
          isLoading.value = false
        }, 300)
      }).catch(
      error => console.log(error)
      )
    }
    const setDate = ((date) => {
      //return moment(date).format('YYYY.MM.DD')
      return moment(date).format('YYYY')
    })
    onMounted(() => {
      getItem()
    })
    onUpdated(() => {
      //console.log('updated!')
    })
    onUnmounted(() => {
      //console.log('unmounted!')
    })
    return{
      item,
      setDate,
      prev,
      isLoading
    }
  }

}

</script>